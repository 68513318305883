require("base-layout");
require("sub-navigation");
require("client-search");
import { renderFamilyPaymentCalculator } from "../../shared_modules/calculator/calculator";
var $ = require("lc-jquery");
require("plans");
var Handlebars = require("handlebars/runtime").default;
var registerHelpers = require("ui-handlebars-helpers");
registerHelpers(Handlebars, true);
var FormManager = require("ui-form-manager");
var validator = require("ui-validator");
var v = validator.validators;
var Modal = require("ui-modal");
var CurrencyInput = require("currency-input");
new CurrencyInput(document);
var calculatorModal = new Modal($("#calculator-modal"));

var faqAccordion = require("faq-accordion");
new faqAccordion(".faq-accordion");

function Family() {
    this.$searchForm = $("#search-form");
    this.formManager = this.registerForm(this.$searchForm);
	this.registerEvents();
}

function setAccessibilityFocus(field) {
  $("#" + field).focus();
}

Family.prototype.registerEvents = function() {
  $("#search-form").on("submit", function(e) {
		e.preventDefault();
		var queryString = $(e.currentTarget).serialize();
		window.location.href = "family/search?" + queryString;
  });

  $(".calculator-modal-button").on("click", function() {
        calculatorModal.show();
        renderFamilyPaymentCalculator(calculatorModal);
        setAccessibilityFocus("amount");
    });
};

Family.prototype.verifyInputFields = function() {
    if (!$("input[name=name]").val() && ! $("input[name=address]").val()) {
        window.location.href = "/family/search?";
    }
};


Family.prototype.successHandler = function(data) {
    if (data.status === 500) {
        window.location.href = "/family/search?name=&address=";
    }

    if (data.total === 1) {
        this.displaySingleResult(data.clients[0]);
    }
    if (data.total > 1 || data.total === 0) {
        this.redirectToSearch();
    }
};

Family.prototype.redirectToSearch = function() {
    var queryString = "name=" + $("input[name=name]").val() + "&address=" + $("input[name=address]").val();
    window.location.href = "family/search?" + queryString;
};

Family.prototype.registerForm = function(form) {
    var formManager = new FormManager(form, {
        endPoint: {
            url: "/family/search",
            dataType: "json",
            method: "GET"
        },
        schema: {
            name: {
                length: v.inputLength({min: 0, max: 100})
            },
            address: {
                length: v.inputLength({min: 0, max: 100})
            }
        }
    });
    return formManager;
};

$(document).ready(function() {
  new Family();
  setAccessibilityFocus("email");
  $(".close-btn").on("click keypress", (event) => {
    if (event.type === "click" || event.which === 13 || event.which === 32){ // mouse click or enter or space
        calculatorModal.hide();
    }
  });
  if (window.location.href.indexOf("calculator=true") >= 0) {
    renderFamilyPaymentCalculator(calculatorModal);
    calculatorModal.show();
    setAccessibilityFocus("amount");
  }
});

module.exports = {};

require('/var/lib/jenkins/workspace/pf-yts-ui_Release_0/src/pages/family/family.less');