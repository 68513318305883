import React from "react";
import { ChatBot, chatConfig } from "lc-chatbot";
import PropTypes from "prop-types";

const ChatWidget = ({ chatFive9Context }) => {
  const { integrationId, defaultChatFields } = chatFive9Context;
  const { prepopulatedFields, l10n } = chatConfig;

  let customFields = [
    {
      k: "Name",
      l: "",
      p: ""
    },
    {
      k: "Question",
      l: "Hi, how can I help you today?",
      p: ""
    }
  ];

  const hasName = defaultChatFields .find(field => (field.k === "name" && field.v?.length > 1));
  // Remove name field if its available thorugh customField
  if (hasName) {
    customFields = customFields.slice(1);
  }
  const config = {
    ...chatConfig,
    l10n: {
      en: {
        messenger: { ...l10n.en.messenger },
        systemMessages: { ...l10n.en.systemMessages },
        captureFields: [ ...customFields]
      }
    },
    prepopulatedFields: [...prepopulatedFields, ...defaultChatFields],
    messenger: {
      ...chatConfig.messenger,
      integrationId
    }
  };
  return <ChatBot config={config} />;
};

ChatWidget.propTypes = {
  chatFive9Context: PropTypes.shape({
    integrationId: PropTypes.string.required,
    defaultChatFields: PropTypes.object
  })
};

export default ChatWidget;

