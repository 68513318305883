import renderCalcComponent from "calculator/calculator-component";

export const renderFamilyPaymentCalculator = (calculatorModal) => {
  const options = {
      navigation: {
          text: "Apply Now",
          className: "payment-calculator__button--apply-now",
          onClick: (e) => {
              calculatorModal.hide();
              window.location.href = "/family/search";
          }
      },
      onPrint: (e, { amount }) => {
          window.open(`/payment-calculator/print?amount=${amount}&page=family`);
          calculatorModal.hide();
      }
  };
  renderCalcComponent(options);
};

