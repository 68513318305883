import http from "lc-http";

const promotionalNoInterest = "Promotional No-Interest";
const promotionalRate = "Promotional Rate";

export const staticConstants = {
  INSTALLMENT_LOAN: {
    displayName: "Fixed Rate Plan",
    pluralName: "Installment Loans"
  },
  INTEREST_FREE_LOAN: {
    displayName: `${promotionalNoInterest} Plan (Waived Interest, Payment Required)`,
    pluralName: `${promotionalNoInterest} Plans (Waived Interest, Payment Required)`
  },
  PROMOTIONAL_RATE_LOAN: {
    displayName: `${promotionalRate} Plan (Low APR, Equal Payment Required)`,
    pluralName: `${promotionalRate} Plans (Low APR, Equal Payment Required)`
  },
  FIXED_RATE_PLAN: {
    displayName: "Fixed Rate Plan",
    pluralName: "Fixed Rate Plans"
  },
  PROMOTIONAL_RATE_PLAN: {
    displayName: `${promotionalRate} Plan (Low APR, Equal Payment Required)`,
    pluralName: `${promotionalRate} Plans (Low APR, Equal Payment Required)`
  },
  PROMOTIONAL_RATE: promotionalRate,
  PROMOTIONAL_NO_INTEREST: promotionalNoInterest,
  LC_TRUE_NO_INTEREST: {
    displayName: "0% APR"
  },
  MA_MIN_AMOUNT: 6001
};

export const constantsSuccess = ({ LC_FIXED_RATE_PLAN: { minRate, maxRate } }) => ({
  ...staticConstants,
  LC_FIXED_RATE_PLAN: {
    displayName: `${minRate}% - ${maxRate}% APR`
  }
});

export const getDynamicConstants = () => http.get("/payment-calculator/rates")
  .then(res => res.data)
  .then(constantsSuccess);

export const AjaxStatus = {
  Initial: 0,
  InProgress: 1,
  Success: 2,
  Error: 3
};

export const REQUIRED_EMPLOY_DETAILS = ["EMPLOYED", "OTHER", "SELFEMPLOYED"];

